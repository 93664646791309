<template>
  <div>
    <!-- 平台对账 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--商户订单时间-->
          <div class="conditions">
            <div>操作时间：</div>
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:320px;margin-right:10px"
              value-format="timestamp"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="true"
            ></el-date-picker>
          </div>
          <div class="conditions">
            <el-input v-model="params.mem_phone" class="ipt-default" style="width: 200px" placeholder="请输入客户手机号" clearable></el-input>
            <el-button class="btn-default" @click="searchEvent()">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column label="发送时间">
          <template
            slot-scope="scope"
          >{{ scope.row.send_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="tpl_name" label="短信类型" />
        <el-table-column prop="mem_nickname" label="客户昵称" />
        <el-table-column prop="mem_phone" label="客户手机号" />
        <el-table-column prop="sms_content" label="短信内容" />
        <el-table-column label="发送状态">
          <template
            slot-scope="scope"
          >{{ scope.row.send_status===1?'发送中':scope.row.send_status==2?'发送成功':'发送失败'  }}</template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注（失败原因）" />
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: "activity-ops-log",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
      },
    };
  },
  mounted() {
    // const end = new Date();
    // const start = new Date();
    // this.advancedForm.times = [start, end];
    this.searchEvent();
  },
  beforeRouteUpdate(to, from, next) {
    this.searchEvent();
    next();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.pageSize = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.pageNo = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.pageNo = 1;
      delete this.params.startTime;
      delete this.params.endTime;
      if (this.advancedForm.times) {
        this.params.startTime = this.advancedForm.times[0] / 1000
        this.params.endTime = this.advancedForm.times[1] / 1000
      }
      this.GET_List();
    },
    generateTimeReqestNumber(date1) {
      const date = new Date(date1);
      return (
        date.getFullYear().toString() +
        this.pad2(date.getMonth() + 1) +
        this.pad2(date.getDate()) +
        this.pad2(date.getHours()) +
        this.pad2(date.getMinutes()) +
        this.pad2(date.getSeconds())
      );
    },
    pad2(n) {
      return n < 10 ? "0" + n : n;
    },
    /** 获取对账列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      request({
        url: 'admin/systems/logs/page',
        method: 'post',
        loading: false,
        params
      })
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
